<template>
  <v-footer app dark padless absolute>
    <v-card class="flex" flat tile>
      <v-card-text class="black py-3 white--text text-center">
        <!-- <a href="https://sclab.co" target="_blank" class="link-font">
          <h4>[ SCLab. ]</h4>
        </a> -->
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  data: () => ({}),
};
</script>

<style scoped>
.link-font {
  text-decoration: none;
  color: white;
}

.link-font:hover {
  color: #9ccc65;
}
</style>
